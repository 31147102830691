import React from 'react'


import './Template.scss';


const Template = () => {
    


    return (
        <div >
            
        </div>
    )
}

export default Template;